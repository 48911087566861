<template>
  <div class="my-order">
    <div class="back" @click="back"><< Back to previous page</div>

    <div class="order">
      <div class="all d-flex">
        <section class="col-9">commodity</section>
        <section class="col-3">quantity</section>
      </div>
    </div>
    <div class="goods" v-for="item in goods">
      <div class="item d-flex">
        <section class="col-2">
          <img :src="item.thumb" alt="" />
        </section>
        <section class="col-4">
          {{ item.description }}
        </section>
        <section class="col-1"></section>
        <section class="col-2" style="color: #666666">
          {{ item.item_name }}
        </section>
        <section class="col-3">{{ item.num }}</section>
      </div>
    </div>

    <div class="form">
      <div class="option">
        <p>* Service type:</p>
        <div class="type">
          <section
            :style="type == 1 ? 'color:#ff7f00;border:1px solid #ff7f00' : ''"
            @click="setType(1)"
          >
            return goods
          </section>
          <section
            :style="type == 2 ? 'color:#ff7f00;border:1px solid #ff7f00' : ''"
            @click="setType(2)"
          >
            Reissue goods
          </section>
        </div>
      </div>

      <div class="popup">
        <p>* Service type:</p>
        <section @click="showUl">
          <input type="text" name="" id="" disabled v-model="option" />
          <img src="~assets/images/icon/loginHover.png" alt="" />
        </section>
      </div>
      <ul v-if="ul">
        <li v-for="item in list" @click="pick(item)">
          {{ item }}
        </li>
      </ul>

      <div class="idea">
        <p>* description:</p>
        <textarea v-model="text" @focus="f_idea()"></textarea>
      </div>
      <div class="hint" v-if="idea">The required fields must not be empty</div>

      <div class="upload">
        <div class="but">
          <p>picture:</p>
          <span @click="upload">Upload pictures</span>
        </div>
        <div class="img-list d-flex">
          <div class="img" v-for="(item, index) in uploadImg">
            <img
              class="img-delete"
              src="~assets/images/icon/delete.png"
              alt=""
              @click="deleteImg(index)"
            />
            <img :src="item" alt="" />
          </div>
        </div>
        <div class="hint">
          <section>
            In order to help you better solve the problem, please upload the
            picture
          </section>
          <p>
            Up to 5 pictures can be uploaded, each picture size is not more than
            5m, support BMP, GIF, JPG, PNG, JPEG format files
          </p>
        </div>
      </div>
    </div>

    <input
      class="file d-none"
      type="file"
      id="imgupload"
      @change="change($event)"
      accept="image/gif,image/jpeg,image/jpg,image/png,image/bmp,image/webp"
    />

    <div class="infomation">
      <div class="i-title">Confirmation information</div>
      <section>Pick up contact: {{ $store.state.userinfo.username }}</section>
      <section>Pick up email: {{ $store.state.userinfo.email }}</section>
      <section>company: {{ $store.state.userinfo.company }}</section>
    </div>
    <div class="submiet">
      <span @click="submit">Confirm submission</span>
    </div>
  </div>
</template>
<script>
import {
  addServiceByOrders,
  getOrdersByid,
  getOrdersitemByid,
  addServiceByOrdersItem,
} from "network/order";
import { upload } from "network/user";
export default {
  name: "RuturnPage",
  data() {
    return {
      ul: false,
      option: "Don't want / like",
      cart: {},
      list: [
        "Don't want / like",
        "Missing / missing / wrong delivery",
        "Goods not received",
        "The outer packing of goods is damaged",
        "Product size / thickness / size is not suitable",
        "Workmanship",
        "Unsuitable color / style",
      ],
      uploadImg: [],
      goods: [],
      type: 1,
      idea: false,
      text: "",
    };
  },

  created() {
    if (this.$route.query.index == 0) {
      getOrdersitemByid(this.$route.query.id).then((res) => {
        if (res.data.code == 1) {
          this.goods.push(res.data.data);
        }
      });
    } else {
      getOrdersByid(this.$route.query.id).then((res) => {
        if (res.data.code == 1) {
          this.goods = res.data.data.ordersgoods;
        }
      });
    }
  },
  methods: {
    submit() {
      if (this.text == "") {
        this.idea = true;
        return;
      }
      let arr = "";
      for (let i = 0; i < this.uploadImg.length; i++) {
        arr += this.uploadImg[i] + ",";
      }
      arr = arr.substr(0, arr.length - 1);

      if (this.$route.query.index == 1) {
        addServiceByOrders(
          this.type,
          this.$route.query.id,
          this.option,
          this.text,
          arr
        ).then((res) => {
          if (res.data.code == 1) {
            this.$router.push("/member/orderSucceeds");
            return;
          } else {
            this.$notify({
              message: res.data.msg,
              type: "error",
              duration: 3000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
          }
        });
      } else {
        addServiceByOrdersItem(
          this.type,
          this.$route.query.id,
          this.option,
          this.text,
          arr
        ).then((res) => {
          if (res.data.code == 1) {
            this.$router.push("/member/orderSucceeds");
            return;
          } else {
            this.$notify({
              message: res.data.msg,
              type: "error",
              duration: 3000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
          }
        });
      }
    },
    f_idea() {
      this.idea = false;
    },

    setType(type) {
      this.type = type;
    },
    back() {
      this.$router.go(-1);
    },
    showUl() {
      this.ul = !this.ul;
    },
    pick(item) {
      this.option = item;
      this.ul = false;
    },
    upload() {
      if (this.uploadImg.length >= 5) {
        this.$notify({
          message: "Upload up to 5 pictures",
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
      } else {
        document.getElementById("imgupload").click();
      }
    },

    deleteImg(index) {
      this.uploadImg.splice(index, 1);
    },

    change(event) {
      var data = new FormData();
      data.append("file", event.target.files[0]);
      upload(data).then((res) => {
        if (res.data.code == 1) {
          this.uploadImg.push(res.data.data.url);
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}
.back {
  font-size: 0.4rem;
  color: #ff7f00;
  cursor: pointer;
  text-decoration: underline;
}

.order {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}

.all {
  padding: 0.25rem;
  background: #f2f2f2;
  font-size: 0.35rem;
  color: #666666;
}
.all section {
  text-align: center;
  padding: 0;
  margin: 0;
}
.form {
  border: 1px solid #ff7f00;
  font-size: 0.35rem;
  margin-top: 1rem;
  border-radius: 0.25rem;
  padding-bottom: 0.5rem;
}
.item {
  border: 1px solid #e0e0e0;
  padding: 0.25rem;
}
.item section {
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 0.3rem;
  color: #333333;
}
.item section img {
  width: 70%;
}
.popup section {
  width: 50%;
  height: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  cursor: pointer;
}
.popup section input {
  width: 90%;
  height: 30px;
  padding: 0;
  margin: 0;
  padding-left: 0.25rem;
  cursor: pointer;
}
.popup section img {
  margin-left: 2.5%;
}
ul {
  margin-left: 110px;
  width: 50%;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
}
li {
  padding: 0.15rem;
  list-style: none;
  cursor: pointer;
}
li:hover {
  color: #ff7f00;
  background: #ffecd9;
}
.popup {
  display: flex;
  margin-top: 0.5rem;
}
.popup p {
  width: 100px;
  margin-right: 0.25rem;
  text-align: end;
}
.idea p {
  width: 100px;
  margin-right: 0.25rem;
  text-align: end;
}
.idea {
  display: flex;
  margin-top: 0.5rem;
}
.idea textarea {
  padding: 0.25rem;
  resize: none;
  width: 60%;
  height: 2.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
}
.but {
  display: flex;
}
.upload {
  margin-top: 0.5rem;
}
.but p {
  width: 100px;
  margin-right: 0.25rem;
  text-align: end;
}
.option .type {
  display: flex;
}
.option .type section {
  margin-right: 0.25rem;
  border: 1px solid #e0e0e0;
  padding: 0.15rem 0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
.option {
  margin-top: 0.5rem;
  display: flex;
}
.option p {
  width: 100px;
  margin-right: 0.25rem;
  text-align: end;
}
.upload span {
  padding: 0.15rem 0.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  color: #999999;
  cursor: pointer;
}
.hint {
  margin-left: 100px;
}
.hint section {
  margin: 0.15rem 0 0 0;
  color: #4c4c4c;
}
.hint p {
  margin: 0.15rem 0 0 0;
  color: #999999;
}
.infomation {
  background: #f2f2f2;
}
.i-title {
  padding: 0.25rem 0.25rem 0 0.25rem;
  color: #333333;
  font-size: 0.4rem;
  font-weight: 600;
}
.infomation {
  padding-bottom: 0.5rem;
  margin: 0.5rem 0 1rem 0;
}
.infomation section {
  padding: 0.5rem 1rem 0 1rem;
  color: #4c4c4c;
  font-size: 0.35rem;
}
.submiet span {
  padding: 0.15rem 0.3rem;
  font-size: 0.35rem;
  color: #fff;
  background: #ff7f00;
  border-radius: 0.15rem;
  cursor: pointer;
}
.submiet {
  margin-bottom: 1.5rem;
  text-align: end;
}
.img-list {
  margin: 20px 0 0 110px;
}
.img {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #e0e0e0;
  margin-right: 0.5rem;
}
.img-delete {
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  cursor: pointer;
}
.hint {
  color: red;
  margin-left: 110px;
}
</style>