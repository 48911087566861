import { request } from "./request";


//修改个人信息
export function getOrderprofileslist(avatar, company, first_name, last_name, tax_id, fax, website, additional_email) {
    return request({
        url: '/api/user/profile',
        method: 'post',
        data: {
            avatar: avatar,
            company: company,
            first_name: first_name,
            last_name: last_name,
            tax_id: tax_id,
            fax: fax,
            website: website,
            additional_email: additional_email
        },
    })
};

export function register(company, first_name, last_name, email, password, tax_id, mobile, website, additional_email, billing, shipping) {
    return request({
        url: '/api/user/register',
        method: 'post',
        data: {
            company: company,
            first_name: first_name,
            last_name: last_name,
            email: email,
            password: password,
            tax_id: tax_id,
            mobile: mobile,
            website: website,
            additional_email: additional_email,
            billing: billing,
            shipping: shipping,
        },
    })
};





//修改手机
export function changemobile(mobile) {
    return request({
        url: '/api/user/changemobile',
        method: 'post',
        data: {
            mobile: mobile,
        },
    })
};

//修改邮箱
export function changeemail(email) {
    return request({
        url: '/api/user/changeemail',
        method: 'post',
        data: {
            email: email,
        },
    })
};


//修改密码
export function changepwd(newpassword) {
    return request({
        url: '/api/user/changepwd',
        method: 'post',
        data: {
            newpassword: newpassword,
        },
    })
};

//忘记重置密码
export function resetpwd(email, newpassword, captcha) {
    return request({
        url: '/api/user/resetpwd',
        method: 'post',
        data: {
            email: email,
            newpassword: newpassword,
            captcha: captcha,
        },
    })
};

//上传头像
export function upload(data) {
    return request({
        url: '/api/common/upload',
        method: 'post',
        data: data,
    })
};



//修改密码
export function logout() {
    return request({
        url: '/api/user/logout',
    })
};